import React from 'react'

import Layout from '../../components/Layout'
import ArtistRoll from '../../components/ArtistRoll'

export default class ArtistIndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <section className="page-header">
          <div className="container">
            <div className="content subheaderlinks">
              <h1 className="breadcrumbs-links">
                Artists
              </h1>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="content">
              <ArtistRoll />
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}
